body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 地図部分のシャドー設定 */
.map-item-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

/* ストリートビューの全画面表示ボタン削除 */
.gm-fullscreen-control {
  display: none;
}

/* カレンダーの天気情報のスタイル */
.calendarItem {
  zoom: 0.15;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
}

.item {
  zoom: 0.3;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
}

/* カレンダーの天気アイコンのスタイル */
.item100 {
  position: absolute;
  left: -0px;
  top: -0px;
}
.item101 {
  position: absolute;
  left: -150px;
  top: -0px;
}
.item102 {
  position: absolute;
  left: -300px;
  top: -0px;
}
.item104 {
  position: absolute;
  left: -450px;
  top: -0px;
}
.item110 {
  position: absolute;
  left: -600px;
  top: -0px;
}
.item112 {
  position: absolute;
  left: 0px;
  top: -80px;
}
.item115 {
  position: absolute;
  left: -150px;
  top: -80px;
}
.item200 {
  position: absolute;
  left: -300px;
  top: -80px;
}
.item201 {
  position: absolute;
  left: -450px;
  top: -80px;
}
.item202 {
  position: absolute;
  left: -600px;
  top: -80px;
}
.item204 {
  position: absolute;
  left: 0px;
  top: -160px;
}
.item210 {
  position: absolute;
  left: -150px;
  top: -160px;
}
.item212 {
  position: absolute;
  left: -300px;
  top: -160px;
}
.item215 {
  position: absolute;
  left: -450px;
  top: -160px;
}
.item300 {
  position: absolute;
  left: -600px;
  top: -160px;
}
.item301 {
  position: absolute;
  left: 0px;
  top: -240px;
}
.item302 {
  position: absolute;
  left: -150px;
  top: -240px;
}
.item303 {
  position: absolute;
  left: -300px;
  top: -240px;
}
.item311 {
  position: absolute;
  left: -450px;
  top: -240px;
}
.item313 {
  position: absolute;
  left: -600px;
  top: -240px;
}
.item314 {
  position: absolute;
  left: 0px;
  top: -320px;
}
.item400 {
  position: absolute;
  left: -150px;
  top: -320px;
}
.item401 {
  position: absolute;
  left: -300px;
  top: -320px;
}
.item402 {
  position: absolute;
  left: -450px;
  top: -320px;
}
.item403 {
  position: absolute;
  left: -600px;
  top: -320px;
}
.item411 {
  position: absolute;
  left: 0px;
  top: -400px;
}
.item413 {
  position: absolute;
  left: -150px;
  top: -400px;
}
.item414 {
  position: absolute;
  left: -300px;
  top: -400px;
}
.item000 {
  position: absolute;
  left: -450px;
  top: -400px;
}
.photo-edit-li::before {
  content: "";
  width: 4px;
  height: 25px;
  display: inline-block;
  background-color: #00cc5b;
  position: absolute;
  top: 10%;
  left: 0;
  opacity: 0;
}
.photo-edit-li-selected::before {
  content: "";
  width: 4px;
  height: 25px;
  display: inline-block;
  background-color: #00cc5b;
  position: absolute;
  top: 10%;
  left: 0;
  opacity: 1;
}